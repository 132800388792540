import React from 'react';
import './style.css';

import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';

export default function Sobre() {
    return (
      <>
        <Header activeMenu="sobre" />
        <div className="header-page-sobre">
          <content>
              <h2>Sobre</h2>
          </content>
        </div>
        <div className="container-page container-page-sobre">
            <p>
            Desde 1948, a Rádio Bragança produz conteúdos de qualidade para toda Bragança Paulista e região, com notícias, informações, músicas, entrevistas e entretenimento para seus ouvintes.
            A Rádio Bragança conta com uma equipe de profissionais experientes e comprometidos com a qualidade.<br/><br/>
            Além da transmissão por Antena, a Rádio Bragança também veicula programas pela internet, utilizando todos os recursos disponíveis para disseminar a informação.<br/><br/>
            A utilização deste novo modelo no jornalismo online possibilita ao receptor a escolha da maneira confortável de receber o conteúdo.<br/><br/>
            Pronta para a era digital, a Rádio Bragança conta com modernas instalações com estúdios para criação e exibição de programas comerciais e anúncios.
            Seja um parceiro da Bragança Paulista.            
            </p>            
        </div>
        <Footer />
      </>
    );
}