import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Home from "../pages/Home";
import Sobre from "../pages/Sobre";
import Noticias from "../pages/Noticias";
import Videos from "../pages/Videos";

import PlayerBar from "../componentes/PlayerBar";

export default function Rotas() {

    return (
      <div>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/sobre" component={Sobre} />
            <Route exact path="/noticias" component={Noticias} />
            <Route exact path="/videos" component={Videos} />
          </Switch>
        </Router>

        <PlayerBar/>
      </div>
      
    );
}

