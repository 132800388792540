import axios from 'axios';

const tokenFace = 'EAACFmqZBPxooBOzcj9KItcb1othMOC2jdMePl2XS8ftAax611gZC1ocyPgJRRZCIKCf2ZBvrqeUzyTzKZBdSAe8CWk53VQITmheSVvefmsJtSShmPgCqi5d7laG0dGRKnEGOWXMomcmcUZCZCAVHF3Y1018Q2NRyEucZBUZCgFMwaZAK6DdHD1o2TPkW8ror6Kh9IZD';
const tokenInsta = 'IGQWRONVhqMmRHU2F6QWhRaThuZA2VMblA3X2xEVVBPWlF3cVM0Vkkxc0U2MnBRT1o2UU1PekFZARVNLTTBPb245a29TYUZArM2pFdFdDSXRoUmFNYTJsR1BMVFYyX29meU02YXNLWm1WeXQzaXROTlNZAWFByYndKQ2cZD';

export const apiFacebook = axios.create({
  baseURL: 'https://graph.facebook.com/v9.0/me?fields=id%2Cname%2Clive_videos%7Bid%2Ctitle%2Cdescription%2Cstatus%2Cstream_url%2Csecure_stream_url%2Cembed_html%2Cvideo%7D&access_token='+tokenFace
});

export const apiInstagram = axios.create({
  baseURL: 'https://graph.instagram.com/me/media?fields=id,username,timestamp,media_type,caption,media_url,thumbnail_url,permalink&access_token='+tokenInsta
});
