import React, { useState, useEffect } from 'react';
import './style.css';
import axios from 'axios';

import Header from '../../componentes/Header';
import Footer from '../../componentes/Footer';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import Loader from '../../componentes/Loarder';
import {apiFacebook, apiInstagram} from '../../services/api';

import NoticiaDetalhes from '../NoticiaDetalhes';

export default function Home() {

  const [loade, setloade] = useState(true);
  const [datafacebook, setdatadatafacebook] = useState([]);
  const [datainstagram, setdatainstagram] = useState([]);
  const [slide, setslide] = useState([]);
  
  const [openDetalhesPost, setopenDetalhesPost] = useState(false);
  const [detalhesPost, setdetalhesPost] = useState([]);

  async function getData(){
      let returndatafacebook = await apiFacebook.get('');
      setdatadatafacebook(returndatafacebook.data);

      let returndatainstagram = await apiInstagram.get('');
      setdatainstagram(returndatainstagram.data);
  }

  // let images = [
  //   {
  //     original: 'https://picsum.photos/id/1018/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1018/250/150/',
  //     originalClass: 'imagem-slide',
  //     description: 'teste'
  //   },
  //   {
  //     original: 'https://picsum.photos/id/1015/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1015/250/150/',
  //     originalClass: 'imagem-slide',
  //     description: 'teste'
  //   },
  //   {
  //     original: 'https://picsum.photos/id/1019/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1019/250/150/',
  //     originalClass: 'imagem-slide',
  //     description: 'teste'
  //   },
  // ];


  //https://dummyimage.com/1347x350/999/555

  async function getSlides(){

    let response = await axios.request({
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://api.desdobrar.com/site/slides/MTA5NjU2NjAwNTA4',
      headers: { }
    });

    let images = [];

    for await (let slide of response.data) {

      images.push(
        {
          original: slide.foto,
          thumbnail: slide.foto,
          originalClass: 'imagem-slide',
          description: slide.titulo
        }
      )
    }

    setslide(images);
  }

  useEffect(async()=>{
    await getSlides();
    await getData();
    setloade(false);
  }, []);

  function closeModalDetalhesPost(){
    setopenDetalhesPost(false);
  }
  function handleDetalhesPost(description, image, data){

    setdetalhesPost({
      description: description,
      image: image,
      data: data
    })
    setopenDetalhesPost(true)
  }


      if(loade){
        return <Loader/>
      }else{
        return (
          <>
            {
              openDetalhesPost &&
              <NoticiaDetalhes close={closeModalDetalhesPost} detalhes={detalhesPost}/>
            }

            
            <Header activeMenu="home" />
            <div className="container-page container-page-home">
              
                <div className="slide-box">
                  <ImageGallery
                    infinite={true}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    autoPlay={true}
                    showBullets={true}
                    showNav={false}
                    slideInterval={7000}
                    items={slide}
                  />            
                </div>

            
                <div className="container-videos">
                    <div className="box-video">
                    {
                      datafacebook?.live_videos?.data?.map( (v, k) =>{
                        if(k <= 0){
                          return (
                            <iframe key={k} className="iframe-video-destaque" src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fradiobragancaam%2Fvideos%2F${v.video.id}%2F&width=640`} width="100%"  scrolling="no" frameborder="0" allowfullscreen="true" allow="clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                          )
                        }
                      })
                    }
                    </div>

                    <div className="box-list-video">
                        {
                          datafacebook?.live_videos?.data?.map( (v, k) =>{
                            if(k > 0 && k <= 5){
                              return (
                                <div key={k}>
                                  <iframe className="iframe-video-destaque-list" src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fradiobragancaam%2Fvideos%2F${v.video.id}%2F&width=640`} width="100%"  scrolling="no" frameborder="0" allowfullscreen="true" allow="clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                </div>
                              )
                            }
                          })
                        }
                    </div>
                </div>

                <div className="container-postagens">
                  <div className="box-title">
                      <h2>Notícias</h2>
                  </div>

                  <div className="section">
                    {
                      datainstagram?.data?.map( (v, k) =>{
                        if(v.media_type === 'IMAGE' ){

                          let data = v.timestamp.replace(/T/, ' ');
                          data.split("-");
                          data = data[8]+data[9]+"/"+data[5]+data[6]+"/"+data[0]+data[1]+data[2]+data[3];
                        

                          return (
                            <div key={k} className="container-post" onClick={()=>{ handleDetalhesPost(v.caption, v.media_url, data) }}>
                               <div className="box-img">
                                <img src={v.media_url} alt="" />
                               </div>
                               <span>{data}</span>
                               <p>{v.caption}</p>
                            </div>
                          )
                        }
                      })
                    }
                    
                  </div>
                </div>

            </div>

            <Footer />

          </>
        );
    }

}