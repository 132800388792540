import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import close from '../../imgs/close.svg';

export default function NoticiaDetalhes(props) {

  function useOutsideAlerter(ref) {
      useEffect(() => {
          function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                  //alert("You clicked outside of me!");
                  props.close();
              }
          }

          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  return (
    <div className="box-modal">
      <div ref={wrapperRef} className="content">
          <div className="header">
            <button onClick={ ()=>{ props.close() } }><img alt="Close" width="20" src={close}/></button>
          </div>
          <div className="body">
              <img alt="" src={props.detalhes.image}/>
              <b>{props.detalhes.data}</b>
              <p>{props.detalhes.description}</p>
          </div>
      </div>
    </div>
  );
}
