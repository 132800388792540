import React, {useState, useEffect} from 'react';
// import React, {useState, useEffect} from 'react';
import './style.css';

import { Link } from "react-router-dom";
import logo from '../../imgs/logo-b.png';

import { FaBars, FaTimes } from "react-icons/fa";


export default function Header(props) {

    const [menuResponsive, setmenuResponsive] = useState(false);
    const [hidemenumobile, sethidemenumobile] = useState(true);

    //RESIZE BROWSER
    window.addEventListener('resize', function(event){
        let larguraDoc = window.screen.availWidth;
        if(larguraDoc >= 930){
            sethidemenumobile(true);
            setmenuResponsive(false);
        }else{
            sethidemenumobile(true);
            setmenuResponsive(false);
        }
    });

    useEffect( ()=>{
        sethidemenumobile(true);
        setmenuResponsive(false);
    }, []);


    let activeHome, activeSobre, activeNoticias,activeVideos = false;

    switch (props.activeMenu) {
        case 'home':
            activeHome = 'active';
            break;
        case 'sobre':
            activeSobre = 'active';
            break;
        case 'noticias':
            activeNoticias = 'active';
            break;
        case 'videos':
            activeVideos = 'active';
            break;
        default:
            
            break;
    }
    
    let menus = <ul>
                    <li><Link className={activeHome} to="/">Home</Link></li>
                    <li><Link className={activeSobre} to="/sobre">Sobre</Link></li>
                    <li><Link className={activeNoticias} to="/noticias">Notícias</Link></li>
                    <li><Link className={activeVideos} to="/videos">Vídeos</Link></li>
                </ul>;    

    return (
    <section>
        <header>
            <div className="container">
                <div className='box-bg-efect-menu'>
                    <div className="section-logo-menu">
                        <Link to="/"> <img alt="Rádio Bragança" src={logo} /> </Link>
                        <div className="menu-icon">
                            { menuResponsive ? <FaTimes onClick={()=>{ setmenuResponsive(false); sethidemenumobile(true); }} size={25} color="#fff"/> : <FaBars onClick={()=>{ setmenuResponsive(true); sethidemenumobile(false); }} size={25} color="#fff"/> }
                        </div>
                    </div>

                    
                    <div className='title-menu'>O som da região.</div>

                    <nav className="menu"> {menus} </nav>
                    <nav className={`menu-mobile ${hidemenumobile ? "hide-menu" : ""}`}> {menus} </nav>
                </div>
            </div>
        </header>
    </section>
    );
}