import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import './style.css';
import { FaFacebookF, FaInstagram } from "react-icons/fa";

export default function PlayerBar(){
    return(
        <main>
            <div className="container">
                <div className="container-player">
                    <div className="container-play">
                        <AudioPlayer
                            autoPlay
                            src="https://radio.desdobrar.com/radio/8010/radio.mp3"
                            onPlay={e => console.log("onPlay")}
                            showJumpControls={false}
                            showDownloadProgress={false}
                            showFilledProgress={false}
                        />
                    </div>

                    <div className="container-sociais">
                        <div className="box-sociais">
                            <a href="https://www.facebook.com/radiobragancaam/" target="_blank"><FaFacebookF size={18} /></a>
                        </div>
                        <div className="box-sociais">
                            <a href="https://www.instagram.com/radiobraganca/" target="_blank"><FaInstagram size={18} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}