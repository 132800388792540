import React from 'react';
import './style.css';
import { Link } from "react-router-dom";

import logoImbramil from '../../imgs/logo-imbramil.png';
import logo from '../../imgs/logo-b.png';

export default function Footer() {
    return (
      <>
      <div className="footer">
          <div className="container">
            
            <div className="section-sobre">
                <content>
                    <Link to="/"><img alt="Radio Bragança" width={150} src={logo}/></Link>
                    <p>Desde 1948, a Rádio Bragança produz conteúdos de qualidade para toda Bragança Paulista e região... <Link to="/sobre">Veja mais</Link></p>
                </content>
            </div>
            <div className="section-apio">
                <h2>Apoio cultural</h2>
                <a href="https://imbramil.com.br/" target="_blank">
                    <img alt="" src={logoImbramil}/>
                </a>
            </div>

          </div>
      </div>
      <div className="footer-corpy">
          <div className="container">
              <p>2020 - Rádio Bragança - Transmissão áudio visual</p>
          </div>
      </div>
      </>
    );
}